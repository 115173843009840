import { InertiaPage } from "@/types/inertia";
import { Link, usePage } from "@inertiajs/react";

const Footer = () => {
    return (
        <footer className='px-14 mb:px-8 lg:py-10 pt-10 pb-14 bg-primary flex flex-wrap gap-x-16 maxMd:gap-x-0 maxMd:px-4 maxMd:pt-10 maxMd:pb-14'>
            <div className="wrapper flex flex-wrap gap-x-16 lgMb:gap-x-10 w-full lgMb:justify-between">
                <div className="lgMb:max-w-[40%] lgMb:w-full">
                    <img src="/storage/static/unidevelopment-logo-white.svg" alt="Logo Unidevelopment" width={277} height={40} className='mb-8 maxMd:hidden' />

                    <img src="/storage/static/unidevelopment-logo-white.svg" alt="Logo Unidevelopment" width={277} height={40} className='maxMd:block hidden mb-8' />

                    <div className="lg:mb-8">
                        <p className='font-extrabold text-white leading-p mb-1 text-p tracking-p'>Inspektor Ochrony Danych</p>
                        <p className='text-white text-label leading-label tracking-small'>
                            w Unidevelopment S.A.: <br />
                            <span className='font-bold'>Jakub Wezgraj</span> <br />
                            <a href="mailto:ochronadanych@unidevelopment.pl" title='ochronadanych@unidevelopment.pl' className='underline'>
                                ochronadanych@unidevelopment.pl
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-wrap gap-x-16 lgMb:gap-x-5 mt-8 items-center mb-8">
                <div className="flex flex-wrap gap-x-16 lgMb:gap-x-5 maxLg:order-2">
                    <a href="/polityka-prywatnosci-kusocinskiego.pdf" target="_blank" title="Polityka prywatności" className='text-white text-p leading-p tracking-small'>
                        Polityka prywatności
                    </a>
                    <a href="/polityka-cookie-kusocinskiego.pdf" className='text-white text-p leading-p tracking-small'>
                        Polityka plików cookies
                    </a>
                </div>
                <div className="flex gap-x-4 lg:ml-16 maxLg:mb-8">
                    <a href="https://pl-pl.facebook.com/Unidevelopment/" target="_blank" title='Facebook'>
                        <img src="/storage/static/fb-color.svg" alt="Facebook" width={32} height={32} />
                    </a>
                    <a href="https://pl.linkedin.com/company/unidevelopment" target="_blank" title='LinkedIn'>
                        <img src="/storage/static/in-color.svg" alt="LinkedIn" width={32} height={32} />
                    </a>
                </div>
            </div>
            <div className="w-full">
                <p className="text-4 text-white -tracking-small text-center maxMd:text-left">
                    Zamieszczone na stronie opisy i wizualizacje nie stanowią oferty w rozumieniu prawa i opublikowane są w celach informacyjnych
                </p>
            </div>
        </footer>
    )
}

export default Footer;